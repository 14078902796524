import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import { t } from '@/main'; // its used for translation. t() same as $t

export class WebpageRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const WebpageRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    WebpageRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    WebpageRendererBaseClass.CustomCategoryTypeId = typeId;
    WebpageRendererBaseClass.ShowImage = true;
    WebpageRendererBaseClass.ShowURL = true;
    WebpageRendererBaseClass.ShowLinkedProgramItem = true;
    WebpageRendererBaseClass.ImageUploadText = t('AddImage').toString();
    WebpageRendererBaseClass.TitleText = t('Title').toString();
    WebpageRendererBaseClass.UrlText = t('CustomCategory.WebUrlText').toString();
    WebpageRendererBaseClass.CustomCategoryHeading = t('CustomCategory.WebPage').toString()+' '+ t('Information.Information').toString();
    return WebpageRendererBaseClass;
  }
}
