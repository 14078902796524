import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
export default class UpdateFolderListing {
  public static createEmpty(): UpdateFolderListing {
    return new UpdateFolderListing();
  }
  constructor(
    public notification: SignalRModelRequest = SignalRModelRequest.createEmpty(),
    public pt: CustomCategoryFolder = CustomCategoryFolder.createEmpty(),
  ) {}
}
