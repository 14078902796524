export class BaseCustomCategoryRenderer {
  public static createEmpty(): BaseCustomCategoryRenderer {
    return new BaseCustomCategoryRenderer();
  }
  constructor(
    public CustomCategoryTypeId: number = 0,
    public CustomCategoryType: string = '',
    public ShowImage: boolean = false,
    public ShowFileUpload: boolean = false,
    public ShowURL: boolean = false,
    public ShowAddress: boolean = false,
    public ShowDescription: boolean = false,
    public ShowVOD: boolean = false,
    public ShowLinkedParticipantProfile: boolean = false,
    public ShowLinkedProgramItem: boolean = false,
    public ShowMultimedia: boolean = false,
    public ImageUploadText: string= '',
    public DescriptionText: string= '',
    public TitleText: string= '',
    public LinkMultipleParticipantProfile: boolean = false,
    public UrlText: string = '',
    public LinkParticipantProfileText: string= '',
    public CustomCategoryHeading: string= '',
    public LinkParticipantProfileNoProfileText: string= '',
  ) { }
}
