import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import { t } from '@/main'; // its used for translation. t() same as $t

export class PresentationRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const PresentationRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    PresentationRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    PresentationRendererBaseClass.CustomCategoryTypeId = typeId;
    PresentationRendererBaseClass.ShowImage = true;
    PresentationRendererBaseClass.ShowFileUpload = true;
    PresentationRendererBaseClass.ShowLinkedProgramItem = true;
    PresentationRendererBaseClass.ImageUploadText = t('AddImage').toString();
    PresentationRendererBaseClass.TitleText = t('Title').toString();
    PresentationRendererBaseClass.UrlText = t('UploadFile').toString();
    PresentationRendererBaseClass.CustomCategoryHeading = t('CustomCategory.PdfDocument').toString();
    return PresentationRendererBaseClass;
  }
}
