
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import CustomCategoryChildComponent from '@/components/CustomCategory/CustomCategoryChildComponent/CustomCategoryChildComponent.vue';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { BaseCustomCategoryRenderer } from '@/entities/CustomCategory/FactoryService/BaseCustomCategoryRenderer';
import { CustomCategoryService } from '@/entities/CustomCategory/FactoryService/CustomCategoryService';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { EventItem } from '@/entities/ApplicationInstance/EventItem';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { StoreHelper } from '@/store/StoreHelper';
import { Store } from '@/main';
@Component({
  components: {
    CustomCategoryChildComponent,
  },
})
class CustomCategoryParentEditPane extends Vue {
  @Prop() private selectedCustomCategoryItem?: CustomCategoryItem;
  @Prop() private customCategoryTypeId?: number;
  @Prop() private availableCustomCategoryFolders?: CustomCategoryFolder[];
  @Prop() private availableEvents?: EventItem[];
  @Prop() private participantGroups?: ParticipantGroups[];
  private selectedCustomCategoryRenderer: BaseCustomCategoryRenderer = BaseCustomCategoryRenderer.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();

  private mounted() {
    // creating child class object for display components and elements in child components i.e customCategoryChildComponent
    if(this.customCategoryTypeId !== undefined && this.customCategoryTypeId !== null && this.customCategoryTypeId > -1) {
      this.selectedCustomCategoryRenderer =  CustomCategoryService.GetCusomCategoryByType(this.customCategoryTypeId);
    }
  }
  private ChangeCustomCategoryType(type: number) {
    // creating child class object for display components and elements in child components i.e customCategoryChildComponent when user change customCategory from dropdown
    this.selectedCustomCategoryRenderer =  CustomCategoryService.GetCusomCategoryByType(type)!;
  }

  @Watch('customCategoryTypeId')
  private oncustomCategoryTypeIdChanged(
    value: number,
  ) {
    // when child class object change to another type
    this.selectedCustomCategoryRenderer =  CustomCategoryService.GetCusomCategoryByType(value)!;
  }
}
export default toNative(CustomCategoryParentEditPane);
