
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { Root, Store, t } from '@/main';
@Component({
  components: {
    ValidationSummary,
  },
})
class CustomCategoryAssignFolders extends Vue {
  @Prop() private folderItems?: CustomCategoryFolder[];
  @Prop() private selectedItems?: CustomCategoryItem[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localFolderItems: CustomCategoryFolder[] = [];
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
    Root,
    Store,
    t,
  );
  // Get folder image
  private GetImageUrl(imgurl: string) {
    if (imgurl === null || imgurl === '') {
      return this.missingImage;
    }
    return imgurl;
  }
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  private mounted() {
    this.localSelectedBulkOperationModel = this.selectedBulkOperationModel!;
    this.localFolderItems = this.folderItems!;
    // insert top level folder
    const objIndex = this.localFolderItems.findIndex(

      (obj: CustomCategoryFolder) => (obj.Id === 0 && obj.Name === this.$t('ProgramAddInformation.TopLevel').toString()),
    );
    if (objIndex === -1) {
      const topLevelFolder = CustomCategoryFolder.createEmpty();
      topLevelFolder.Name = this.$t('ProgramAddInformation.TopLevel').toString();
      topLevelFolder.FontAwesomeIconName = 'fa fa-folder-open';
      this.localFolderItems.unshift(topLevelFolder);
    }


    this.UncheckAllFolders();
  }
  // Assign folder to selected items
  private addItem(item: CustomCategoryFolder) {
    const objIndex = this.localFolderItems.findIndex(
      (obj: CustomCategoryFolder) => obj.Id === item.Id,
    );
    this.UncheckAllFolders();
    item.IsSelected = true;
    this.localFolderItems[objIndex] = item;
  }
  // Remove folder from selected items
  private removeItem(item: CustomCategoryFolder) {
    item.IsSelected = false;
    const objIndex = this.localFolderItems.findIndex(
      (obj: CustomCategoryFolder) => obj.Id === item.Id,
    );
    this.UncheckAllFolders();
    this.localFolderItems[objIndex] = item;
  }
  // Unselect all folders
  private UncheckAllFolders() {
    if (this.localFolderItems) {
      this.localFolderItems.forEach((element) => {
        element.IsSelected = false;
      });
    }
  }
  private CloseFolder() {
    this.$emit('selectBack');
  }

  private SaveFolder() {
    const objIndex = this.localFolderItems.findIndex(
      (obj: CustomCategoryFolder) => obj.IsSelected === true,
    );
    if (objIndex > -1) {
      const selectedFolder: CustomCategoryFolder = this.localFolderItems[
        objIndex
      ];
      const selectedCustomCategoryIds: number[] = this.signalR.GetSelectedCustomCategoryIdsToSendInJson(
        this.selectedItems!,
      );
      this.localSelectedBulkOperationModel.SelectedIds = selectedCustomCategoryIds;
      this.localSelectedBulkOperationModel.JSONData = JSON.stringify({
        folderId: selectedFolder.Id,
      });
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(
            this.localSelectedBulkOperationModel,
            this.$t('UserName.You').toString(),
          ),
        },
      ).toString();
      this.signalR.PerformCustomCategoryBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      Root.emit('customCategory-uncheck-select-all');
    } else {
      this.validationErrorFields = [];
      this.validationErrorFields.push(this.$t('Folders.SelectFolder').toString());
      this.visibleValidationSummary = true;
    }
  }
}
export default toNative(CustomCategoryAssignFolders);
