import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "right-container-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCategoryChildComponent = _resolveComponent("CustomCategoryChildComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomCategoryChildComponent, {
      ref: "EditCustomCategoryItem",
      "selected-custom-category-item": Object.assign({}, _ctx.selectedCustomCategoryItem),
      "selected-base-custom-category-renderer": _ctx.selectedCustomCategoryRenderer,
      "available-custom-category-folders": _ctx.availableCustomCategoryFolders,
      "custom-category-type-id": _ctx.customCategoryTypeId,
      "available-events": _ctx.availableEvents,
      "participant-groups": _ctx.participantGroups,
      onCustomCategoryType: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ChangeCustomCategoryType($event)))
    }, null, 8, ["selected-custom-category-item", "selected-base-custom-category-renderer", "available-custom-category-folders", "custom-category-type-id", "available-events", "participant-groups"])
  ]))
}