
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { Root } from '@/main';
@Component
class CustomCategoryLinkedProgramListView extends Vue {
  @Prop() private linkedProgram?: ProgramItem[];
  @Prop() private selectedCustomCategoryId?: number;
  private localLinkedProgram?: ProgramItem[] = [];
  private localAllPrograms?: ProgramItem[] = [];
  private localLinkedProgramLoading?: boolean = false;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private loadingImage: string = require('@/assets/Images/loading.png');
  private addIconImage: string = require('@/assets/Images/Add-icon.svg');

  private mounted() {
    if (this.linkedProgram != null) {
      this.localLinkedProgram = [];
      this.localAllPrograms = [];
      this.linkedProgram.forEach((element) => {
        if (element.IsLinked) {
          this.localLinkedProgram!.push(element);
        }
        this.localAllPrograms!.push(element);
      });
    }
    Root.on('customCategoryDetailLoading', (loading: boolean) => {
      this.localLinkedProgramLoading = loading ;
    });
    Root.on(
      'linkedProgramData',
      (program: ProgramItem[]) => {
        this.localLinkedProgram = [];
        this.localAllPrograms = [];
        (program).forEach((element) => {
          if (element.IsLinked) {
            this.localLinkedProgram!.push(element);
          }
          this.localAllPrograms!.push(element);
        });
      },
    );
    Root.on('clearPrograms', () => {
      this.localLinkedProgram = [];
      this.localAllPrograms = [];
    });
  }
  // Get image url
  private GetImageUrl(item: ProgramItem) {
    const customCategoryImageUrl = this.localLinkedProgram!.find(
      (x) => x.Id === item.Id,
    )!.ImageUrl;
    if (customCategoryImageUrl !== null) {
      return customCategoryImageUrl;
    }
    return this.missingImage;
  }
  // Switch to AddProgramWithEntity to add or remove linked program
  private clickOnAddProgram() {
    this.$emit('showAttachProgram', {
      showAddPrograms: true,
      programs: this.localAllPrograms,
    });
  }
}
export default toNative(CustomCategoryLinkedProgramListView);
