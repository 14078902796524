import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e027b65"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "dvMultimediaEdit",
  class: "grid-listing inline-flex100"
}
const _hoisted_2 = {
  key: 0,
  class: "edit-pane-container"
}
const _hoisted_3 = { class: "edit-section" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "edit-link-image" }
const _hoisted_6 = {
  class: "hyperLink",
  href: "javascript:void(0)"
}
const _hoisted_7 = { class: "input-width" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "error-vuelidate valign" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "submit-position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.localMultimediaItem && !_ctx.showImageProcess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "img-select",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnImage(true)))
            }, [
              _createElementVNode("img", {
                class: "iconImg iconImg",
                src: _ctx.GetImageUrl()
              }, null, 8, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t("Button.Edit")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.localMultimediaItem.Title.$model) = $event)),
                type: "text",
                class: _normalizeClass(["form-control input-from-image", _ctx.status(_ctx.v$.localMultimediaItem.Title)]),
                maxlength: "70",
                placeholder: _ctx.$t('MultimediaImageTitle')
              }, null, 10, _hoisted_8), [
                [
                  _vModelText,
                  _ctx.v$.localMultimediaItem.Title.$model,
                  void 0,
                  { trim: true }
                ]
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.v$.localMultimediaItem.Title.$error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("Validation.MultimediaTitleMissing")), 1))
                  : _createCommentVNode("", true),
                (_ctx.v$.localMultimediaItem.ThumbUrl.$error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("Validation.NoFileUploaded")), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-size-default",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("SaveButton")), 1),
              _createElementVNode("button", {
                class: "btn btn-secondary btn-size-default margin-left-15",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("CancelButton")), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}