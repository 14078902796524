
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import loading from '@/components/Common/loading.vue';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import { FolderSignalRModel } from '@/signalRRequest/CustomCategory/FolderSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { FetchData, Root, Store } from '@/main';
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
@Component({
  components: {
    ImageProcess,
    loading,
  },
})
class CustomCategoryFolderEditItemPane extends Vue {
  public showLoading = false;
  public loaderBorderColor?: string = '';
  @Prop() private selectedFolderItem?: CustomCategoryFolder;
  @Prop() private applicationborderColor?: string;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private addIcon = require('@/assets/Images/add-icon-image.svg');
  private showImageProcess = false;
  private localFolderItem: CustomCategoryFolder | null = null;
  private isButtonDisabled = false;

  // variables for validation
  private state = reactive({
    localFolderItem: null as CustomCategoryFolder | null,
  });

  private rules = {
    localFolderItem: {
      Name: { required, minLength: minLength(1) },
    } ,
  };

  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);

  private created() {
    if (this.selectedFolderItem != null) {
      this.localFolderItem = CustomCategoryFolder.createEmpty();
      // assign current image url to old image url property for cancel operation
      if (
        this.selectedFolderItem.OldIconUrl === '' ||
        this.selectedFolderItem.OldIconUrl == null
      ) {
        this.selectedFolderItem.OldIconUrl = this.selectedFolderItem.IconUrl;
        this.selectedFolderItem.OldIconUrlOriginal = this.selectedFolderItem.IconUrlOriginal;
        this.selectedFolderItem.OldName = this.selectedFolderItem.Name;
      }
      if (!this.selectedFolderItem.OldFontAwesomeIconId || this.selectedFolderItem.OldFontAwesomeIconId === 0) {
        this.selectedFolderItem.OldFontAwesomeIconId = this.selectedFolderItem.FontAwesomeIconId;
        this.selectedFolderItem.OldFontAwesomeIconName = this.selectedFolderItem.FontAwesomeIconName;
      }

      this.localFolderItem = Object.assign(
        {},
        this.selectedFolderItem,
      );
    }
  }

  private mounted() {
    const self = this;
    this.state.localFolderItem = this.localFolderItem!;
    Root.on(
      'customCategory-folder-item-imageupdated-operation',
      (pt: CustomCategoryFolder) => {
        const updatedFolderItem = pt ;
        if (updatedFolderItem != null) {
          if (updatedFolderItem.Id === self.localFolderItem!.Id) {
            this.localFolderItem!.PinnedToMenu = updatedFolderItem.PinnedToMenu;
          }
        }
      },
    );
  }
  private Cancel(val: any) {
    val.$reset();
    // update initial values to selected folder item for cancel operation
    this.selectedFolderItem!.IconUrl = this.selectedFolderItem!.OldIconUrl!;
    this.selectedFolderItem!.IconUrlOriginal = this.selectedFolderItem!.OldIconUrlOriginal!;
    this.selectedFolderItem!.Name = this.selectedFolderItem!.OldName!;
    this.selectedFolderItem!.FontAwesomeIconId = this.selectedFolderItem!.OldFontAwesomeIconId!;
    this.selectedFolderItem!.FontAwesomeIconName = this.selectedFolderItem!.OldFontAwesomeIconName!;
    this.$emit('customCategory-folder-item-cancel-operation', this.selectedFolderItem);
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    const signalR: FolderSignalRModel = new FolderSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();

    if (this.localFolderItem!.Id === 0) {
      signalObject = signalR.SingleCreateFolderItemSignalRModelRequest;
    } else {
      signalObject = signalR.SingleUpdateFolderItemSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localFolderItem!.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(signalObject, this.$t('folderName.You').toString()),
    }).toString();

    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.localFolderItem!.Name});
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    customCategoryFolderItem: CustomCategoryFolder,
  ) {
    const signalR: FolderSignalRModel = new FolderSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(customCategoryFolderItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetFolderItemSendToSignalR(customCategoryFolderItem);
    signalR.PerformFolderSingleOperation(signalObject);
  }
  private async Submit(val: any) {
    const res = await val.$validate();
    if(!res) {
      return;
    }
    // No errors submitting.
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    if (this.localFolderItem!.PinnedToMenu) {
      this.localFolderItem!.IconUrl = null;
      this.localFolderItem!.IconUrlOriginal = null;
    }
    this.isButtonDisabled = true;
    const signalObject = this.SendNotificationRequest();
    FetchData('/CustomCategory/EditCustomFolder',
      JSON.stringify(this.localFolderItem))
      .then((response: CustomCategoryFolder) => {
        // Successful update / save
        this.$emit(
          'customCategory-folder-item-edit-operation',
          response,
        );
        this.SendNotificationRequestToQueue(
          signalObject,
          response,
        );
      })
      .catch(() => {
        // Log error if required
      });
  }
  // Pin/ Unpin folders
  private PinToMenu() {
    this.RemoveIcon();
    if (this.localFolderItem!.PinnedToMenu) {
      this.localFolderItem!.FontAwesomeIconId = 0;
      this.localFolderItem!.FontAwesomeIconName = '';
      this.localFolderItem!.PinnedToMenu = false;
    } else {
      this.localFolderItem!.FontAwesomeIconId = 17;
      this.localFolderItem!.FontAwesomeIconName = 'fa fa-info-circle';
      this.localFolderItem!.PinnedToMenu = true;
    }
  }
  private RemoveIcon() {
    this.localFolderItem!.IconUrl = '';
    this.localFolderItem!.IconUrlOriginal = '';
  }
  private GetImageUrl() {
    return this.localFolderItem!.IconUrl === null
      ? this.missingImage
      : this.localFolderItem!.IconUrl === ''
        ? this.missingImage
        : this.localFolderItem!.IconUrl;
  }
  // To show image process
  private clickOnImage(val: boolean) {
    this.selectedFolderItem!.Name = this.localFolderItem!.Name;
    this.showImageProcess = val;
    this.$emit('customCategory-folder-item-edit-operation', this.localFolderItem);
    if (this.localFolderItem!.PinnedToMenu) {
      this.$emit('folder-information-fonticon', val);
    } else {
      this.$emit('customCategory-folder-item-imageUpload-operation', val);
    }

  }
  // Get image url
  private SetImageIconUrl(iconUrl: string, iconUrlOriginal: string) {
    this.localFolderItem!.IconUrl = iconUrl;
    this.localFolderItem!.IconUrlOriginal = iconUrlOriginal;
    this.$emit('customCategory-folder-item-imageUpload-completed', this.localFolderItem);
  }
  private GetPlaceholder(): string {
    return this.$t('Folder.Placeholder').toString();
  }
}
export default toNative(CustomCategoryFolderEditItemPane);
