import { TabViewModel } from '@/entities/TabViewModel';
import { t } from '@/main';

export class CustomCategoryTabList {
  public TabViewModels: TabViewModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor() {
    this.TabViewModels.push({ Id: 1, ComponentName: 'Information', Header: t('Information').toString() ,IsDisabled: false});
  }
}
