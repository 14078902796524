
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { SearchItem } from '@/entities/SearchItem';
// import  CustomCategory  from '@/views/CustomCategory.vue';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import Avatar from 'vue3-avatar';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import { StoreHelper } from '@/store/StoreHelper';
import Common from '@/helper/Common';
import { Store, Root } from '@/main';
@Component({
  components: {
    Avatar,
  },
})

class CommonSearchComponent extends Vue {
  // Properties
  @Prop() private searchListing?: SearchItem[];
  @Prop() private searchLinkedListing?: SearchItem[];
  @Prop() private searchPlaceholder?: string;
  @Prop() private searchTitle?: string;
  @Prop() private searchInformationText?: string;
  @Prop() private isMultipleParticipant?: boolean;
  @Prop() private selectedCustomerTypeId?: number;
  @Prop() private noParticipantFoundText?: string;
  @Prop() private selectedCustomerCategoryItem?: CustomCategoryItem;
  @Prop() private hasParticipant?: boolean;
  @Prop() private count?: number;
  // Local variables
  private localSearchPlaceholder = '';
  private localSearchTitle = '';
  private localSearchNoticeLineText = '';
  private localIsMultipleParticipant = false;
  private localHideAddBtton = false;
  // private customCategory = new CustomCategory();
  private localSearchText = '';
  private timeout: any = null;
  private searchApiTimeoutTime = 500;
  private localSearchItem: SearchItem[] = [];
  private localSearchLinkedListing: SearchItem[] = [];
  private localSelectedCustomerCategoryItem = new CustomCategoryItem();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private localHasParticipant = false;
  private localCount = 0;
  private showSearchNoticeText = false;
  private isMobileView = false;

  // Called on mounted
  private mounted() {
    this.localSearchItem = this.searchListing!;
    this.localSearchLinkedListing = this.searchLinkedListing!;
    this.localSearchPlaceholder = this.searchPlaceholder!;
    this.localSearchTitle = this.searchTitle!;
    this.localSearchNoticeLineText = this.searchInformationText!;
    this.localIsMultipleParticipant = this.isMultipleParticipant!;
    this.localSelectedCustomerCategoryItem = this.selectedCustomerCategoryItem!;
    this.localHasParticipant = this.hasParticipant!;
    this.localCount = this.count!;
    const selectedIds = this.localSearchLinkedListing.map(({ ParticipantId }) => ParticipantId);
    // Update our local variable IsAdded to true
    selectedIds.forEach((element) => {
      const objIndex = this.localSearchItem.findIndex(((obj) => obj.Id === element));
      if(objIndex >= 0) {
        this.localSearchItem[objIndex].IsAdded = true;
        if(this.localIsMultipleParticipant === false) {
          this.localHideAddBtton = true;
        }
      } else {
        const objIndexLinked = this.localSearchLinkedListing.findIndex(((obj) => obj.ParticipantId === element));
        this.localSearchLinkedListing[objIndexLinked].Id = this.localSearchLinkedListing[objIndexLinked].ParticipantId;
        this.localSearchLinkedListing[objIndexLinked].IsAdded = true;
        this.localSearchItem.unshift(this.localSearchLinkedListing[objIndexLinked]);
        if(this.localCount > this.localSearchItem.length) {
          this.localSearchItem.splice((this.localSearchItem.length -1),1);
        }
        if(this.localIsMultipleParticipant === false) {
          this.localHideAddBtton = true;
        }
      }
    });
    // `true` values first
    this.localSearchItem.sort((value) =>
      value.IsAdded ? -1 : 1,
    );
    this.ShowSearchNoticeText();
    // Update listing after search and sort by isadded and name
    Root.on('change-listing-after-search',
      (searchItem: SearchItem[]) => {
        this.localSearchItem= searchItem ;
        const selectedId = this.localSearchLinkedListing.map(({ ParticipantId }) => ParticipantId);
        selectedId.forEach((element) => {
          const objIndex = this.localSearchItem.findIndex(((obj) => obj.Id === element));
          if(objIndex >= 0) {
            this.localSearchItem[objIndex].IsAdded = true;
          } else {
            if( this.localSearchText === '') {
              const objIndexLinked = this.localSearchLinkedListing.findIndex(((obj) => obj.ParticipantId === element));
              this.localSearchLinkedListing[objIndexLinked].Id = this.localSearchLinkedListing[objIndexLinked].ParticipantId;
              this.localSearchLinkedListing[objIndexLinked].IsAdded = true;
              this.localSearchItem.unshift(this.localSearchLinkedListing[objIndexLinked]);
              if(this.localCount > this.localSearchItem.length) {
                this.localSearchItem.splice((this.localSearchItem.length -1),1);
              }
            }
          }
          if(this.localIsMultipleParticipant === false) {
            this.localHideAddBtton = true;
          }
        });
        this.localSearchItem.sort((value) =>
          value.IsAdded ? -1 : 1 || value.Name ? 1 : -1, // `true` values first
        );
        this.ShowSearchNoticeText();
      },
    );
    // Update linked listing after search
    Root.on('change-linkedlisting-after-search',
      (searchLinkedListing: SearchItem[]) => {
        this.localSearchLinkedListing = searchLinkedListing ;
      },
    );
    // Check the isMobileView or not and scroll to top if not mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (!this.isMobileView) {
      Common.ScrollToTop('dvAddParticipants');
    }
  }
  // Show Search Notice text
  private ShowSearchNoticeText() {
    const self = this;
    if(self.localCount > self.localSearchItem.length && self.localSearchItem.length !== 0 && self.localSearchText ==='') {
      self.showSearchNoticeText = true;
    } else {
      self.showSearchNoticeText = false;
    }
  }
  // Clear Search Text
  private ClearSearchText() {
    this.localSearchText = '';
    this.SearchString();
  }
  // get by default color for Background color
  private GetDefaultBackgroundColor() {
    const appDefaultColorsInfo: AppColorSettingsInfo = this.storeHelper.GetApplicationColorSettingsFromStore();
    return appDefaultColorsInfo.topNavBackgroundColor;
  }
  // get by default color for Foreground color
  private GetDefaultForegroundColor() {
    const appDefaultColorsInfo: AppColorSettingsInfo = this.storeHelper.GetApplicationColorSettingsFromStore();
    return appDefaultColorsInfo.topNavForegroundColor;
  }
  // Check IconUrl is Valid or not
  private IsIconUrlValid(iconUrl: string) {
    if(iconUrl !== '' && iconUrl !== null && iconUrl !== undefined) {
      return true;
    } else {
      return false;
    }
  }
  // Click Close
  private ClickClose() {
    this.localSearchLinkedListing.sort((value) =>
      value.Name ? -1 : 1, // `true` values first
    );
    this.$emit('close-click', this.localSearchLinkedListing);
    Root.emit('linkedParticipantsData', this.localSearchLinkedListing);
  }
  // on Key Search
  private onKeySearch(event: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(this.timeout);
    const $this = this;
    if (event.keyCode === 13) {
      $this.SearchString();
    } else{
      this.timeout = setTimeout(function() {
        $this.SearchString();
      }, this.searchApiTimeoutTime);
    }
  }
  // Get Intials for avtar
  private GetIntials(firstName: string, lastName: string) {
    if(lastName !== '') {
      return firstName.charAt(0) + ' ' + lastName.charAt(0);
    } else {
      return firstName.charAt(0);
    }
  }
  // Add Item
  private AddItem(item: SearchItem) {
    const entityIds: number[]=[this.selectedCustomerTypeId!];
    const participantIds: number[]=[item.Id];
    const path = '/CustomCategory/AddParticpantsLinkToCustomCategory';
    Common.prototype.AddParticipansLink(path, entityIds, participantIds);
    const objIndex=this.GetIndexOfSearchItem(item);
    this.localSearchItem[objIndex].IsAdded = true;
    const newItem = new SearchItem();
    newItem.ParticipantId = this.localSearchItem[objIndex].Id;
    newItem.FirstName = this.localSearchItem[objIndex].FirstName;
    newItem.LastName = this.localSearchItem[objIndex].LastName;
    newItem.Name = this.localSearchItem[objIndex].Name;
    newItem.Company = this.localSearchItem[objIndex].Company;
    newItem.Title = this.localSearchItem[objIndex].Title;
    this.localSearchLinkedListing.push(newItem);
    this.localSelectedCustomerCategoryItem.LinkedParticipantsItems = this.localSearchLinkedListing;
    if(this.localIsMultipleParticipant === false) {
      this.localHideAddBtton = true;
    }
    this.SendNotification();
    this.$forceUpdate();
  }
  // Send Notification
  private SendNotification() {
    const customCategoryItemData={
      dataItem: this.localSelectedCustomerCategoryItem,
      customCategoryDetailType: 'LinkedParticipant',
    };
    Root.emit('linked-category-add-participant-notification', customCategoryItemData);
  }
  // Get Index Of Search Item
  private GetIndexOfSearchItem(item: SearchItem) {
    const objIndex=this.localSearchItem.findIndex(((obj) => obj.Id===item.Id));
    return objIndex;
  }
  // Remove Item
  private RemoveItem(item: SearchItem) {
    const entityIds: number[]=[this.selectedCustomerTypeId!];
    const participantIds: number[]=[item.Id];
    const path = '/CustomCategory/RemoveParticpantsLinkToCustomCategory';
    Common.prototype.RemoveParticipansLink(path, entityIds, participantIds);
    const objIndex=this.GetIndexOfSearchItem(item);
    this.localSearchItem[objIndex].IsAdded = false;
    const objIndexLinkedParticipant = this.localSearchLinkedListing.findIndex(((obj) => obj.ParticipantId===item.Id));
    this.localSearchLinkedListing.splice(objIndexLinkedParticipant,1);
    this.localSelectedCustomerCategoryItem.LinkedParticipantsItems = this.localSearchLinkedListing;
    if(this.localIsMultipleParticipant === false) {
      this.localHideAddBtton = false;
    }
    this.SendNotification();
    this.$forceUpdate();
  }
  // Search String
  private SearchString() {
    Root.emit('searchItem-search-fromsearchcomponent',this.localSearchText);
    this.$forceUpdate();
  }
  @Watch('isMultipleParticipant')
  private onisMultipleParticipantChanged(
    value: boolean,
  ) {
    this.isMultipleParticipant = value;
  }
  @Watch('searchListing')
  private onsearchListingChanged(
    val: SearchItem[],
  )  {
    this.localSearchItem = val;
  }
  @Watch('searchLinkedListing')
  private onSearchLinkedListingChanged(
    value: SearchItem[],
  ) {
    this.localSearchLinkedListing = value;
  }
  @Watch('selectedCustomerCategoryItem')
  private onSelectedCustomerCategoryItem(
    value: CustomCategoryItem,
  ) {
    this.localSelectedCustomerCategoryItem = value;
  }
  @Watch('hasParticipant')
  private onHasParticipantChanged(
    val: boolean,
  ) {
    this.localHasParticipant = val;
  }
}
export default toNative(CommonSearchComponent);
