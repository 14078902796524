import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import { t } from '@/main';
export class SpeakerRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const SpeakerRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    SpeakerRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    SpeakerRendererBaseClass.CustomCategoryTypeId = typeId;
    SpeakerRendererBaseClass.ShowImage = true;
    SpeakerRendererBaseClass.ShowDescription = true;
    SpeakerRendererBaseClass.ShowLinkedParticipantProfile = true;
    SpeakerRendererBaseClass.ShowLinkedProgramItem = true;
    SpeakerRendererBaseClass.ShowMultimedia = true;
    SpeakerRendererBaseClass.ImageUploadText = t('AddImage').toString();
    SpeakerRendererBaseClass.DescriptionText = t('CustomCategory.AboutSpeaker').toString();
    SpeakerRendererBaseClass.LinkParticipantProfileText = t('CustomCategory.LinkSpeakerParticipantText').toString();
    SpeakerRendererBaseClass.TitleText = t('Name').toString();
    SpeakerRendererBaseClass.CustomCategoryHeading =  t('CustomCategory.Speaker').toString()+' '+ t('Information.Information').toString();
    SpeakerRendererBaseClass.LinkParticipantProfileNoProfileText = t('CustomCategory.LinkParticipantProfileNoProfileText', {type: SpeakerRendererBaseClass.CustomCategoryType}).toString();
    SpeakerRendererBaseClass.ShowVOD = true;
    return SpeakerRendererBaseClass;
  }
}
