
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import { CustomCategoryMultimediaItem } from '@/entities/CustomCategory/CustomCategoryMultimediaItem';
import CustomCategoryMultimediaEditItemPane from '@/components/CustomCategory/CustomCategoryDetail/Multimedia/CustomCategoryMultimediaEditItemPane.vue';
import { FetchData, Root } from '@/main';
@Component({
  components: {
    CustomCategoryMultimediaEditItemPane,
    loading,
  },
})
class CustomCategoryMultimediaListAndEditItemView extends Vue {
  @Prop() private selectedEditMultimediaItem?: CustomCategoryMultimediaItem;
  @Prop() private applicationborderColor?: string;
  private localMultimediaItem: CustomCategoryMultimediaItem | null = null;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private manageIcon = require('@/assets/Images/three-dot-icon.svg');
  private showEditMultimedia?: boolean = false;
  private loaderBorderColor = '';
  private showLoading = false;
  private isMobileView = false;

  @Watch('selectedEditMultimediaItem')
  private onChangeMultimediaItem(val: CustomCategoryMultimediaItem) {
    this.localMultimediaItem = val;
  }
  private mounted() {
    if (this.selectedEditMultimediaItem != null) {
      this.localMultimediaItem = this.selectedEditMultimediaItem;
    }
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    const self = this;
    Root.on(
      'customCategory-multimedia-item-imageupdated-operation',
      (mi: any) => {
        if (mi != null) {
          if (mi.selectedMultimediaitem.Id === self.localMultimediaItem!.Id) {
            const selectedItem: CustomCategoryMultimediaItem = mi.selectedMultimediaitem;
            self.selectItem(selectedItem, true);
          }
        }
      },
    );
    // check for ipad and mobile view
    if (window.innerWidth <= 768) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    Root.on(
      'customCategory-multimedia-close-others-operation',
      () => {
        this.showEditMultimedia = false;
      },
    );
  }
  private selectItem(image: CustomCategoryMultimediaItem, scroll: boolean) {
    Root.emit('customCategory-multimedia-close-others-operation');
    const selectedItem = image;
    this.localMultimediaItem = selectedItem;
    this.showEditMultimedia = true;
    this.$emit('customCategory-multimedia-item-select-operation', selectedItem);
    if (scroll) {
      const el = document.querySelector('#dvMultimediaListing');
      if (el) {
        this.scrollToBottom(el);
      }
    }
  }
  private CustomCategoryMultimediaItemImageEditedOperation(
    multimedia: CustomCategoryMultimediaItem,
  ) {
    this.selectItem(multimedia, false);
  }
  // Cancel the update operation
  private CustomCategoryMultimediaItemCancelOperation(multimedia: CustomCategoryMultimediaItem) {
    this.showEditMultimedia = false;
    this.localMultimediaItem = multimedia;
  }
  // Edit multimedia item
  private CustomCategoryMultimediaItemEditedOperation(multimedia: CustomCategoryMultimediaItem) {
    this.localMultimediaItem = multimedia;
    this.showEditMultimedia = false;
    this.$emit('customCategory-multimedia-item-edit-operation', multimedia);
  }

  private CustomCategoryMultimediaItemImageUploadOperation(val: boolean) {
    this.$emit('customCategory-multimedia-item-imageUpload-operation', val);
  }

  private DeleteSelectedMultimedia(multimedia: CustomCategoryMultimediaItem) {
    this.Delete(multimedia);
  }
  // Get multimedia image
  private GetImageUrl(item: CustomCategoryMultimediaItem) {
    if (item !== null) {
      return item.ThumbUrl;
    }
    return this.missingImage;
  }
  // Delete multimedia item
  private Delete(multimedia: CustomCategoryMultimediaItem) {
    if (!confirm('This will irrevobaly remove this multimedia\n\nContinue?')) {
      return;
    }
    FetchData('/CustomCategory/DeleteCustomCategoryMediaItem', JSON.stringify({
      id: multimedia.Id,
      customCategoryId: multimedia.CustomCategoryId,
    }))
      .then((response) => {
        if (response.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          // need information send to signalr
          const multimediaItemData = {
            id: multimedia.Id,
            customCategoryDetailType: 'Multimedia-Delete',
          };
          Root.emit('customCategory-multimedia-update-signalr', multimediaItemData);
        }
      })
      .catch(() => {
        // Log error if required
      });
  }

  private scrollToBottom(el: Element) {
    setTimeout(() => {
      el.scrollTop = el.scrollHeight;
      el.scrollIntoView();
    }, 100);
  }
}
export default toNative(CustomCategoryMultimediaListAndEditItemView);
