/* eslint-disable max-len */
import { BaseBulkOperationsModel } from '../BaseBulkOperationsModel';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
export class CustomCategoryBulkOperations extends BaseBulkOperationsModel {
  constructor(rootObj: any, $store: any, $t: any) {
    super();
    const storeHelper: StoreHelper = new StoreHelper($store);
    const appInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    const isEnableFeaturedImage = !appInfo.EnableLinkProgramItemsToGroup;
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(rootObj, $store, $t);

    this.arrBulkOperations.push({ Id: 1, Name: 'Delete', IconUrl: require('@/assets/Images/delete-background-icon.svg'), IconPadding: 'padding: 10px 17px;', Description: 'Permanently delete your selected items', ConfirmationMessage: 'Notifications.SureDeleteInformationItems', ConfirmationMessageSubTitle: '', objModel: signalR.DeleteSelectedCustomCategorySignalRModelRequest, ShowConfirmationPopup: true });

    if (isEnableFeaturedImage) {
      this.arrBulkOperations.push({ Id: 2, Name: 'Edit Icons & Images', IconUrl: require('@/assets/Images/edit-icons.svg'), IconPadding: 'padding: 10px 17px;', Description: 'Apply an icon or a featured image to selected items', ConfirmationMessage: 'Notifications.SureUpdateInformationItemsImage', ConfirmationMessageSubTitle: '', objModel: signalR.EditSelectedCustomCategoryIconsAndImagesSignalRModelRequest, ShowConfirmationPopup: false });
    } else {
      this.arrBulkOperations.push({ Id: 2, Name: 'Edit Icons', IconUrl: require('@/assets/Images/edit-icons.svg'), IconPadding: 'padding: 10px 17px;', Description: 'Apply an icon to selected items', ConfirmationMessage: 'Notifications.SureUpdateInformationItemsImage', ConfirmationMessageSubTitle: '', objModel: signalR.EditSelectedCustomCategoryIconsAndImagesSignalRModelRequest, ShowConfirmationPopup: false });
    }

    this.arrBulkOperations.push({ Id: 3, Name: 'Assign to Folders', IconUrl: require('@/assets/Images/change-folder-icon.svg'), IconPadding: 'padding: 10px 17px;', Description: 'Add or edit your selected items into folders', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkAssignFolderSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 4, Name: 'Link Programs', IconUrl: require('@/assets/Images/add-to-program.svg'), IconPadding: 'padding: 10px 17px;', Description: 'Link program items to your selected items', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkLinkProgramSignalRModelRequest, ShowConfirmationPopup: false });
  }
}
