import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import { t } from '@/main'; // its used for translation. t() same as $t

export class SponsorRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const SponsorRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    SponsorRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    SponsorRendererBaseClass.CustomCategoryTypeId = typeId;
    SponsorRendererBaseClass.ShowImage = true;
    SponsorRendererBaseClass.ShowDescription = true;
    SponsorRendererBaseClass.ShowLinkedParticipantProfile = true;
    SponsorRendererBaseClass.ShowLinkedProgramItem = true;
    SponsorRendererBaseClass.ImageUploadText = t('AddImage').toString();
    SponsorRendererBaseClass.DescriptionText = t('CustomCategory.AboutSponsor').toString();
    SponsorRendererBaseClass.LinkParticipantProfileText = t('CustomCategory.LinkSponsorParticipantText').toString();
    SponsorRendererBaseClass.LinkMultipleParticipantProfile = true;
    SponsorRendererBaseClass.TitleText = t('Name').toString();
    SponsorRendererBaseClass.CustomCategoryHeading =  t('CustomCategory.Sponsor').toString()+' '+ t('Information.Information').toString();
    SponsorRendererBaseClass.LinkParticipantProfileNoProfileText = t('CustomCategory.LinkParticipantProfileNoProfileText', {type: SponsorRendererBaseClass.CustomCategoryType}).toString();
    SponsorRendererBaseClass.ShowVOD = true;
    SponsorRendererBaseClass.ShowURL = true;
    SponsorRendererBaseClass.UrlText = t('CustomCategory.SponsorUrlText').toString();
    SponsorRendererBaseClass.ShowMultimedia = true;
    return SponsorRendererBaseClass;
  }
}
