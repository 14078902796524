import { SearchItem } from '@/entities/SearchItem';
export default class ChangeVisibilitySearch {
  public static createEmpty(): ChangeVisibilitySearch {
    return new ChangeVisibilitySearch();
  }
  constructor(
    public SearchPlaceholder: string = '',
    public SearchTitle: string = '',
    public SearchLinkedListing: SearchItem[] = [],
    public SearchString: string = '',
  ) {}
}
