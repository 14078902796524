
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import {SignalRModelRequest} from '@/signalRRequest/SignalRModelRequest';
import { EventItem } from '@/entities/ApplicationInstance/EventItem';
import Common from '@/helper/Common';
import { Store, Root, FetchData } from '@/main';
@Component({
  components: {
    ValidationSummary,
  },
})
class CustomCategoryShareWithOtherEvents extends Vue {
  @Prop() private allEvents?: EventItem[];
  @Prop() private selectedCustomCategoryId?: number;
  @Prop() private isBulkOperation?: boolean;
  @Prop() private selectedItems?: CustomCategoryItem[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;
  @Prop() private linkedEvents?: number[];
  @Prop() private isSharedVideoWarningVisibleForShareWithOtherEvent?: boolean;

  private localAllEvents?: EventItem[] = [];
  private localLinkedEvents?: number[] = [];
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private overwriteLinkedProgram = false;
  private isMobileView = false;
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private localIsSharedVideoWarningVisibleForShareWithOtherEvent = false;
  private signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(
    Root,
    Store,
    this.$t,
  );
  private APIMissingImagePath = '/content/images/ImageMissing.png';

  // Mounted lifecycle event of Component
  private mounted() {
    if (this.allEvents != null) {
      this.localAllEvents = this.allEvents;
    }
    if (this.linkedEvents !=  null) {
      this.localLinkedEvents = this.linkedEvents;
    }
    this.UncheckAllEvents();
    this.SetSelecteEvents();
    this.localSelectedBulkOperationModel = this.selectedBulkOperationModel!;
    this.visibleValidationSummary = false;
    this.localIsSharedVideoWarningVisibleForShareWithOtherEvent = this.isSharedVideoWarningVisibleForShareWithOtherEvent!;
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (!this.isMobileView) {
      Common.ScrollToTop('dvShareWithevents');
    }
  }
  // Use to set the selected event
  private SetSelecteEvents() {
    if (this.linkedEvents != null) {
      const self = this;
      this.linkedEvents.forEach(function(value) {
        const objIndex = self.localAllEvents!.findIndex(
          (obj: EventItem) => obj.Id === value,
        );
        if (objIndex > -1) {
          self.localAllEvents![objIndex].IsLinked = true;
        }
      });
    }
  }
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Close Add clickOnBackCustomCategoryEdit Section
  private clickOnBackCustomCategoryEdit() {
    this.$emit('selectBack', {
      showShareWithOtherEevents: false,
      events: this.localAllEvents,
    });
    setTimeout(() => {
      Root.emit('customCategoryDetailLoadingVod', false);
    }, 500);
  }
  // To share it to other event
  private addItem(item: EventItem) {
    item.IsLinked = true;
  }
  // unselect all at the time of component load
  private UncheckAllEvents() {
    this.localAllEvents!.forEach((element) => {
      element.IsLinked = false;
    });
  }

  // Remove a Linked Information from  program Item
  private removeItem(item: EventItem) {
    item.IsLinked = false;
  }
  // Save to share the information with the selected event
  private SaveShareWithOtherEvents() {
    const objIndex = this.localAllEvents!.findIndex(
      (obj: EventItem) => obj.IsLinked === true,
    );
    if (objIndex > -1) {

      const eventItems: number[] = [];
      this.localAllEvents!.forEach(function(value) {
        if (value.IsLinked === true) {
          eventItems.push(value.Id);
        }
      });
      const informationIds: number[] = [];
      informationIds.push(this.selectedCustomCategoryId!);
      this.$emit('custom-category-shared-event-notification', {evnts: this.localAllEvents , showLoading: true});
      FetchData('/CustomCategory/AttachCustomCategoryToEvents', JSON.stringify({
        customCategoryIds: informationIds.join(),
        selectedEventIds: eventItems.join(),
      }))
        .then((response) => {
          if (response.success === false) {
            alert(this.$t('Message.UnableToSave'));
          } else {
            this.$emit('custom-category-shared-event-notification', {evnts: this.localAllEvents , showLoading: false});
            setTimeout(() => {
              Root.emit('customCategoryDetailLoadingVod', false);
            }, 500);
          }
        })
        .catch(() => {
          // Log error if required
        });


    } else {
      this.validationErrorFields = [];
      this.validationErrorFields.push(this.$t('InformationShareEvent.SelectEvent').toString());
      this.visibleValidationSummary = true;
    }
  }

  private GetImageUrl(imgurl: string) {
    return imgurl === (null || this.APIMissingImagePath) ? this.missingImage : imgurl;
  }
}
export default toNative(CustomCategoryShareWithOtherEvents);
