import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25ab7572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-container mobile-horizontal-scroll" }
const _hoisted_2 = { class: "width100" }
const _hoisted_3 = { class: "main-heading primary-heading" }
const _hoisted_4 = { class: "text-right" }
const _hoisted_5 = { class: "subheading margin-top-30" }
const _hoisted_6 = { class: "inline-flex100 margin-top-10" }
const _hoisted_7 = { class: "image-container" }
const _hoisted_8 = { class: "imgdivDefault" }
const _hoisted_9 = { class: "btn-upload" }
const _hoisted_10 = { class: "fontawesomeStyleDiv" }
const _hoisted_11 = { class: "fontawesomeStyle" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "divRoundCorner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["inline-flex100 align-items-center", {'minHeight45': _ctx.isMobileView,'minHeight50':!_ctx.isMobileView}])
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-size-default",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.BackToParent()))
        }, _toDisplayString(_ctx.$t("ImageProcess.back")), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.HeadingTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-size-default",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.BackToParent()))
        }, _toDisplayString(_ctx.$t("ImageProcess.save")), 1)
      ])
    ], 2),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("ImageProcess.lblSelectIcon")), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("i", {
                  class: _normalizeClass([_ctx.selectedFontAwesomeIcon.IconName, "icon-position"])
                }, null, 2)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fontAwesomeIcons, (FontAwesomeIcon) => {
              return (_openBlock(), _createElementBlock("div", {
                key: FontAwesomeIcon.Id
              }, [
                _createElementVNode("div", {
                  class: "col-xs-3 font-icon-area",
                  onClick: ($event: any) => (_ctx.ClickIconGenerator(FontAwesomeIcon))
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("i", {
                      class: _normalizeClass(FontAwesomeIcon.IconName),
                      style: _normalizeStyle(_ctx.GetFontAwesomeIconIconNameClass())
                    }, null, 6)
                  ])
                ], 8, _hoisted_12)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}