import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import { t } from '@/main'; // its used for translation. t() same as $t

export class OtherInformationRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const OtherInformationRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    OtherInformationRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    OtherInformationRendererBaseClass.CustomCategoryTypeId = typeId;
    OtherInformationRendererBaseClass.ShowImage = true;
    OtherInformationRendererBaseClass.ShowAddress = true;
    OtherInformationRendererBaseClass.ShowDescription = true;
    OtherInformationRendererBaseClass.ShowLinkedProgramItem = true;
    OtherInformationRendererBaseClass.ShowMultimedia = true;
    OtherInformationRendererBaseClass.ImageUploadText = t('AddImage').toString();
    OtherInformationRendererBaseClass.DescriptionText = t('Description').toString();
    OtherInformationRendererBaseClass.TitleText = t('Title').toString();
    OtherInformationRendererBaseClass.CustomCategoryHeading =  t('Other').toString() +' '+ t('Information.Information').toString();
    OtherInformationRendererBaseClass.ShowVOD = true;
    return OtherInformationRendererBaseClass;
  }
}
