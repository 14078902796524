export class SearchItem {
  public static createEmpty(): SearchItem {
    return new SearchItem();
  }
  constructor(
    public ApplicationInstanceCustomCategoryId: number = 0,
    public Company: string = '',
    public CreatedBy: string = '',
    public CreatedOn: Date = new Date(),
    public FirstName: string = '',
    public IconUrl: string = '',
    public Id: number = 0,
    public IsDeleted: boolean = false,
    public LastName: string = '',
    public Name: string = '',
    public ParticipantId: number = 0,
    public Title: string = '',
    public UpdatedBy: string = '',
    public UpdatedOn: Date = new Date(),
    public IsAdded: boolean = false,
  ) { }
}
