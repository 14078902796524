// import i18n from '@/i18n';
export class CustomCategoryType {
  public static createEmpty(): CustomCategoryType {
    return new CustomCategoryType();
  }
  constructor(
    public Id: number = 0,
    public Name: string = '',
    public IconUrl: string = '',
    public Description: string = '',
    public DisplayName: string = '',
  ) { }
}
export class CustomCategoryTypes extends CustomCategoryType {
  public arrCustomCategoryOptions: CustomCategoryType[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(rootObj: any, $store: any, $t: any) {
    super();
    this.arrCustomCategoryOptions.push({Id: 0, Name: $t('CustomCategory.Speaker').toString(), IconUrl: require('@/assets/Images/speaker-icon.svg'), Description: 'Add a speaker. Link to participant profile and program item(s)',DisplayName:$t('CustomCategory.Speaker').toString()});
    this.arrCustomCategoryOptions.push({Id: 1, Name: $t('CustomCategory.Sponsor').toString(), IconUrl: require('@/assets/Images/sponsor-icon.svg'), Description: 'Add a sponsor. Link to participant profile and program item(s)',DisplayName:$t('CustomCategory.Sponsor').toString()});
    this.arrCustomCategoryOptions.push({Id: 2, Name: $t('CustomCategory.Presentation').toString(), IconUrl: require('@/assets/Images/pdf-icon.svg'),  Description: 'Upload hand-outs, presentation decks, venue maps, food menus, etc',DisplayName:$t('CustomCategory.PdfDocument').toString()});
    this.arrCustomCategoryOptions.push({Id: 3, Name: $t('CustomCategory.WebPage').toString(), IconUrl: require('@/assets/Images/Webpage-icon.svg'),   Description: 'Link to a webpage or other URL',DisplayName:$t('CustomCategory.WebPage').toString()});
    this.arrCustomCategoryOptions.push({Id: 4, Name: $t('CustomCategory.SocialMedia').toString(), IconUrl:require('@/assets/Images/social-icon.svg'), Description: 'Add link to LinkedIn, Instagram, Twitter, Facebook, and more',DisplayName:$t('CustomCategory.SocialMedia').toString()});
    this.arrCustomCategoryOptions.push({Id: 5, Name: $t('CustomCategory.OtherInformation').toString(), IconUrl: require('@/assets/Images/information-icon.svg'),   Description:   'Add any other text-based information',DisplayName:$t('CustomCategory.OtherInformation').toString()});
  }
}
