export class CustomCategoryMultimediaItem {
  public static createEmpty(): CustomCategoryMultimediaItem {
    return new CustomCategoryMultimediaItem();
  }
  constructor(
    public Id: number = 0,
    public Title: string = '',
    public Url: string = '',
    public ThumbUrl: string = '',
    public Type: string = '',
    public CustomCategoryId: number= 0,
    public IsImage: boolean = false,
    public IsVideo: boolean = false,
    // retain values for cancel operation
    public OldUrl: string= '',
    public OldThumbUrl: string= '',
    public OldTitle: string= '',
  ) { }
}
