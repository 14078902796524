import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import { t } from '@/main'; // its used for translation. t() same as $t

export class SocialMediaRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const SocialMediaRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    SocialMediaRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    SocialMediaRendererBaseClass.CustomCategoryTypeId = typeId;
    SocialMediaRendererBaseClass.ShowImage = true;
    SocialMediaRendererBaseClass.ShowURL = true;
    SocialMediaRendererBaseClass.ShowLinkedProgramItem = true;
    SocialMediaRendererBaseClass.ImageUploadText = t('AddImage').toString();
    SocialMediaRendererBaseClass.TitleText = t('Name').toString();
    SocialMediaRendererBaseClass.UrlText = t('CustomCategory.SocialMediaUrlText').toString();
    SocialMediaRendererBaseClass.CustomCategoryHeading = t('CustomCategory.SocialMedia').toString()+' '+ t('Information.Information').toString();
    return SocialMediaRendererBaseClass;
  }
}
