enum CustomCategoryTypeEnum  {
  Speaker = 0,
  Sponsor = 1,
  Presentation = 2,
  Webpage = 3,
  SocialMedia = 4,
  OtherInformation = 5,
  CreateNew  = -1,
}
export default Object.freeze(CustomCategoryTypeEnum);
