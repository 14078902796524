import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import { SpeakerRenderer } from './SpeakerRenderer';
import { WebpageRenderer } from './WebpageRenderer';
import { SponsorRenderer } from './SponsorRenderer';
import { SocialMediaRenderer } from './SocialMediaRenderer';
import { PresentationRenderer } from './PresentationRenderer';
import { OtherInformationRenderer } from './OtherInformationRenderer';
// Interface to create base template
export interface Create {
  GetValues(typeId: number): BaseCustomCategoryRenderer;
}
// Classes name for the CustomCategoryType
const CustomCategoryClassType = {
  0: SpeakerRenderer,
  1: SponsorRenderer,
  2: PresentationRenderer,
  3: WebpageRenderer,
  4: SocialMediaRenderer,
  5: OtherInformationRenderer,
};
// Create the keys name as per typeof CustomCategoryType
export type Keys = keyof typeof CustomCategoryClassType;
type CustomCategoryTypes = typeof CustomCategoryClassType[Keys];
// Extract Instance Type
type ExtractInstanceType<T> = T extends new () => infer R ? R : never;
// Return the base class as per keys value
export class UserFactory {
  public static GetCustomCategoryInstance(K: Keys): ExtractInstanceType<CustomCategoryTypes> {
    return new CustomCategoryClassType[K]();
  }
}
