
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { CustomCategoryMultimediaItem } from '@/entities/CustomCategory/CustomCategoryMultimediaItem';
import loading from '@/components/Common/loading.vue';
import { FetchData, Root } from '@/main';
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
@Component({
  components: {
    loading,
  },
})
class CustomCategoryMultimediaEditItemPane extends Vue {
  @Prop() private selectedMultimediaItem?: CustomCategoryMultimediaItem;
  @Prop() private applicationborderColor?: string;
  @Prop() private selectedMultimediaId?: number;
  private showLoading = false;
  private loaderBorderColor = '';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private showImageProcess = false;
  private localMultimediaItem: CustomCategoryMultimediaItem | null = null;

  // variables for validation
  private state = reactive({
    localMultimediaItem: null as CustomCategoryMultimediaItem | null,
  });

  private rules = {
    localMultimediaItem: {Title: { required, minLength: minLength(1) },
      ThumbUrl: { required }},
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);

  private Cancel(val: any) {
    val.$reset();
    // update full image src in selectedMultimedia item for cancel operation
    this.selectedMultimediaItem!.Url = this.selectedMultimediaItem!.OldUrl;
    this.selectedMultimediaItem!.ThumbUrl = this.selectedMultimediaItem!.OldThumbUrl;
    this.selectedMultimediaItem!.Title = this.selectedMultimediaItem!.OldTitle;
    this.$emit(
      'customCategory-multimedia-item-cancel-operation',
      this.selectedMultimediaItem,
    );
  }

  private clickOnImage(val: boolean) {
    // retain textbox value if switch to image component
    this.selectedMultimediaItem!.Title = this.localMultimediaItem!.Title;
    this.showImageProcess = val;
    this.$emit('customCategory-multimedia-item-imageUpload-operation', val);
  }
  private GetImageUrl() {
    return this.localMultimediaItem!.ThumbUrl === null
      ? this.missingImage
      : this.localMultimediaItem!.ThumbUrl === ''
        ? this.missingImage
        : this.localMultimediaItem!.ThumbUrl;
  }
  private created() {
    if (this.selectedMultimediaItem != null) {
      this.localMultimediaItem = CustomCategoryMultimediaItem.createEmpty();
      // to retain values for cancel operation
      if (
        this.selectedMultimediaItem.OldUrl === '' ||
        this.selectedMultimediaItem.OldUrl == null
      ) {
        this.selectedMultimediaItem.OldUrl = this.selectedMultimediaItem.Url;
        this.selectedMultimediaItem.OldThumbUrl = this.selectedMultimediaItem.ThumbUrl;
        this.selectedMultimediaItem.OldTitle = this.selectedMultimediaItem.Title;
      }

      this.localMultimediaItem = Object.assign(
        this.localMultimediaItem,
        this.selectedMultimediaItem,
      );
      this.state.localMultimediaItem = this.localMultimediaItem;
    }
  }

  private async Submit(val: any) {
    const res = await val.$validate();
    if(!res) {
      return;
    }
    // No errors submitting.
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    FetchData('/CustomCategory/EditCustomCategoryMedia',
      JSON.stringify(this.localMultimediaItem))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.ValidationErrors[0]));
        } else {
          // need information send to signalr
          const multimediaItemData = {
            dataItem: response.MultimediaItem,
            customCategoryDetailType: 'Multimedia',
          };
          Root.emit('customCategory-multimedia-update-signalr', multimediaItemData);

          this.$emit(
            'customCategory-multimedia-item-edit-operation',
            response.MultimediaItem,
          );
          Root.emit(
            'customCategory-multimedia-item-edit-operation',
            response.MultimediaItem,
          );
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
}
export default toNative(CustomCategoryMultimediaEditItemPane);
